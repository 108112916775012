import React from "react";
import IndexPage from "./index"

const IndexPageSV = () => (
  <IndexPage i18n="sv"/>
);

export default IndexPageSV;


